import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import AboutDesktop from '~components/AboutDesktop'
import AboutMobile from '~components/AboutMobile'
import useBreakpoint from '~utils/useBreakpoint'
import styled from '@emotion/styled'
import { mobile } from '~styles/global'

const About = ({ data }) => {
	const { page } = data
	const { isMobile } = useBreakpoint()

	return (
		<>
			<Seo
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			{isMobile ?
				<AboutMobile content={page} />
				:
				<AboutDesktop content={page} />
			}
			<DataViewer data={page} name="page" />
		</>
	)
}


About.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query AboutQuery($slug: String) {
		page: sanityAboutPage(slug: {current: {eq: $slug}}) {
			title
			aboutTitle
			aboutText: _rawAboutText(resolveReferences: {maxDepth: 5})
			showreelTitle
			showreels {
				title 
				reel {
					...videoPlayer
				}
				reelVimeo {
					desktopVideo
					mobileVideo
					showMuteToggle
				}
				reelMux{
					desktopVideo{
						asset: _rawAsset(resolveReferences: {maxDepth: 3})
					}
					mobileVideo{
						asset: _rawAsset(resolveReferences: {maxDepth: 3})
					}
				}
			}
			contactTitle
			contactText: _rawContactText(resolveReferences: {maxDepth: 5})
			studioImages {
				...imageWithCaption
				asset {
					metadata {
						dimensions {
							aspectRatio
						}
					}
				}

			}
			servicesTitle
			servicesText: _rawServicesText(resolveReferences: {maxDepth: 5})
			servicesLeft: services {
				services {
					text
					link {
						...link
					}
				}
			}
			servicesRight{
				services {
					text
					link {
						...link
					}
				}
			}
			workButton {
				text
				link {
					...link
				}
			}
			pressTitle
			press {
				text
				link {
					...link
				}
			}
			events {
				text
				link {
					...link
				}
			}
			awardsTitle
			awards {
				text
				link {
					...link
				}
			}
			clientsTitle
			clients {
				text
				link {
					...link
				}
			}
			opportunitiesTitle
			opportunitiesText: _rawOpportunitiesText(resolveReferences: {maxDepth: 5})
			seo {
				...seo
			}
		}
	}
`

export default About